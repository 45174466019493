import axios from 'axios';
import config from '../config/env-constants';

export default {
  baseUrl: `https://us-central1-elog-${config.currEnv === 'prod' ? '' : config.currEnv + '-'}app.cloudfunctions.net`,
  
  getDailyTimeRecord(filterBy) {
    let url = `${this.baseUrl}/getDailyTimeRecord?view=Admin`;
    return axios.post(url, { filterBy: JSON.stringify(filterBy) });
  },

  getUserFormLogs(filterBy) {
    let url = `${this.baseUrl}/getUserFormLogs?view=Admin`;
    return axios.post(url, { filterBy: JSON.stringify(filterBy) });
  }

}