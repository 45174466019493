import { db } from '../config/firebase';


export const getUserDetailsByEmail = async email => {
  let user = {};

  const query = db.collection('users').where('email', '==', email).limit(1);
  const querySnapshot = await query.get();

  querySnapshot.forEach(doc => {
    user = {
      id: doc.id,
      ...doc.data()
    }
  });

  return user;
}

export const verifyLoginAttempts = async email => {
  const queryRef = db.collection('userSessions').doc(email);
  return queryRef.get().then(result => {
    let isLocked = false;
    
    if (result.exists) {
      let attempts = result.data().attempts;
      if (attempts >= 3) {
        isLocked = true;
      }
    }

    return isLocked;
  });
}