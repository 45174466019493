export const setUser = user => {
  localStorage.setItem('user', JSON.stringify(user));
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}

// Current Worklog
export const setCurrWorkLog = currWorkLog => {
  localStorage.setItem('currWorkLog', JSON.stringify(currWorkLog));
}

export const getCurrWorkLog = () => {
  return JSON.parse(localStorage.getItem('currWorkLog'));
}

// Current User Form Log
export const setCurrUserFormLog = currUserFormLog => {
  localStorage.setItem('currUserFormLog', JSON.stringify(currUserFormLog));
}

export const getCurrUserFormLog = () => {
  return JSON.parse(localStorage.getItem('currUserWorkLog'));
}

// MainHub
export const setMainHub = mainHub => {
  localStorage.setItem(`mainHub_${mainHub.userId}`, JSON.stringify(mainHub));
}

export const getMainHub = userId => {
  return JSON.parse(localStorage.getItem(`mainHub_${userId}`));
}

export const remove = item => {
  localStorage.removeItem(item);
}

export const removeAll = () => {
  localStorage.clear();
}
