<template>
  <b-modal id="change-main-hub" title="Change Main Hub" @show="onReset" @hidden="onReset" @ok.prevent="handleOk" centered
    ok-title="Save Changes" no-close-on-backdrop size="sm">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <b-form-group>
      <b-form-radio-group v-model="selected" :options="options" stacked />
    </b-form-group>
  </b-modal>
</template>

<script>
import { bus } from "../../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UserWorklogSetupDB from "../../database/userWorklogSetup";

export default {
  name: "change-main-hub",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      assignedHubs: [],
      selected: {},
      options: [],
    };
  },
  created() {
    this.fetchAssignedHubs();
  },
  mounted() {
    bus.$on("onChangeHub", () => {
      this.$bvModal.show("change-main-hub");
      this.selected = this.$store.getters["worklog/getCurrMainHub"];
    });
  },
  methods: {
    async fetchAssignedHubs() {
      try {
        const user = this.$store.getters["auth/getCurrentUser"];
        this.assignedHubs = await UserWorklogSetupDB.getAssignedHubs(user.id);
        this.assignedHubs.forEach((hub) => {
          hub.userId = user.id;
          this.options.push({
            text: hub.name,
            value: hub,
          });
        });
      } catch (error) {
        this.$toast.error(
          "There was a problem fetching Assigned Hubs. Please refresh the page.",
          "Oops!"
        );
      }
    },
    async handleOk() {
      this.isLoading = true;
      const hub = this.selected;
      this.$store.commit("worklog/SET_MAIN_HUB", hub);
      await this.$store.dispatch("worklog/fetchCurrWorkLog", hub);
      this.isLoading = false;
      this.$bvModal.hide("change-main-hub");
      this.$toast.success(
        `Your main hub has been changed to ${hub.name}`,
        "Success!"
      );
    },
    onReset() { },
  },
};
</script>