import DateUtil from '../../utils/date';
import FilterUtil from '../../utils/filter';
import { setCurrUserFormLog, setCurrWorkLog, setMainHub, getCurrUserFormLog, getCurrWorkLog, getMainHub, getUser } from '../../utils/localStorage';
import ReportAPI from '../../api/report';
import UserWorklogSetupDB from '../../database/userWorklogSetup';
import _ from 'lodash';

const currentDate = DateUtil.getDateFormatForField(new Date().getTime());

export default {
  state: {
    mainHub: {
      id: '',
      name: '',
      qrcode: '',
      companyId: '',
      company: ''
    },
    currWorkLog: {},
    currUserFormLog: {}
  },
  getters: {
    getCurrMainHub(state) {
      const user = getUser();

      let mainHub = state.mainHub;
      // get mainHub from localStorage
      let mainHubFromLocalStorage = getMainHub(user.id);
      if (!_.isEmpty(mainHubFromLocalStorage) && !_.isEmpty(mainHubFromLocalStorage.companyId)) {
        mainHub = mainHubFromLocalStorage;
      }
      return mainHub;
    },
    getCurrWorkLog(state) {
      let currWorkLog = state.currWorkLog;
      if (!_.isEmpty(getCurrWorkLog())) {
        currWorkLog = getCurrWorkLog();
      }
      return currWorkLog;
    },
    getCurrUserFormLog(state) {
      let currUserFormLog = state.currUserFormLog;
      if (!_.isEmpty(getCurrUserFormLog())) {
        currUserFormLog = getCurrUserFormLog();
      }
      return currUserFormLog;
    }
  },
  mutations: {
    SET_MAIN_HUB(state, payload) {
      state.mainHub = payload;
      setMainHub(payload);
    },
    SET_CURR_WORKLOG(state, payload) {
      state.currWorkLog = payload;
      setCurrWorkLog(payload);
    },
    SET_CURR_USER_FORM_LOG(state, payload){
      state.currUserFormLog = payload;
      setCurrUserFormLog(payload);
    }
  },
  actions: {
    async fetchAssignedHubs({ commit, dispatch, rootGetters }) {
      const user = rootGetters['auth/getCurrentUser'];
      const assignedHubs = await UserWorklogSetupDB.getAssignedHubs(user.id);

      let hub = {};
      if (!_.isEmpty(assignedHubs)) {
        if (assignedHubs.length === 1) {
          hub = assignedHubs[0];
        } else {
          const mainHub = getMainHub(user.id);
          if (_.isEmpty(mainHub)) {
            hub = assignedHubs[0];
          } else {
            let currHub = _.find(assignedHubs, o => { return o.qrcode === mainHub.qrcode });
            if (!_.isEmpty(mainHub)
              && !_.isEmpty(currHub)
              && !_.isEmpty(mainHub.companyId)
            ) {
              hub = mainHub;
            } else {
              hub = assignedHubs[0];
            }
          }
        }
      }

      if (!_.isEmpty(hub)) {
        hub.userId = user.id;
        commit('SET_MAIN_HUB', hub);
        await dispatch('fetchCurrWorkLog', hub);
      } else {
        commit('SET_MAIN_HUB', {});
        commit('SET_CURR_WORKLOG', {});
      }
    },
    async fetchCurrWorkLog({ commit, rootGetters }, payload) {
      const user = rootGetters['auth/getCurrentUser'];
      let filterBy = FilterUtil.getFilterBy({
        user: { id: user.id },
        userCompany: { id: user.companyId },
        hub: { id: payload.id },
        hubCompany: { id: payload.companyId },
        dateFrom: currentDate,
        dateTo: currentDate
      });
      const { data } = await ReportAPI.getDailyTimeRecord(filterBy);
      const currWorkLog = data.items[0];
      if (currWorkLog) {
        commit('SET_CURR_WORKLOG', currWorkLog);
      } else {
        commit('SET_CURR_WORKLOG', {});
      }
    },
    async fetchCurrUserFormLog({ commit, rootGetters }, payload) {
      const user = rootGetters['auth/getCurrentUser'];
      let filterBy = FilterUtil.getFilterBy({
        user: { id: user.id },
        userCompany: { id: user.companyId },
        dateFrom: currentDate,
        dateTo: currentDate
      });
      const { data } = await ReportAPI.getUserFormLogs(filterBy);
      const currUserFormLog = data.items[0];
      if (currUserFormLog) {
        commit('SET_CURR_USER_FORM_LOG', currUserFormLog);
      } else {
        commit('SET_CURR_USER_FORM_LOG', {});
      }
    }
  }
}