<template>
  <div class="main-content">
    <dashboard-navbar></dashboard-navbar>
    <fade-transition :duration="200" origin="center top" mode="out-in">
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    FadeTransition,
  }
};
</script>
