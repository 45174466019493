<template>
  <header class="header-global customized-nav-bar">
    <base-nav class="navbar navbar-light" id="navbar-main" expand>
      <!-- Filler for dashboard expanded view -->
      <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto" />

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <base-dropdown tag="li" class="nav-item">
          <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="fa fa-user-circle"></i> &nbsp;&nbsp;
            <span class="nav-link-inner--text account-name">
              {{ $store.getters["auth/getDisplayName"] }}
            </span>
          </a>

          <a v-if="hasAssignedHub" v-b-modal.change-main-hub @click.prevent="onChangeHub" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Change Main Hub</span>
          </a>
          <div class="dropdown-divider"></div>
          <a v-b-modal.download-qrcode @click.stop="onDownloadQRCode" class="dropdown-item">
            <i class="fa fa-qrcode"></i>
            <span>View QR Code</span>
          </a>
          <div class="dropdown-divider"></div>
          <a :href="true" @click.prevent="onLogout" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </base-dropdown>
      </ul>
      <change-main-hub />
      <print-qr-code />
    </base-nav>
  </header>
</template>

<script>
import EventBus from '@/shared/event-bus';
import ChangeMainHub from "../views/home/ChangeMainHub";
import PrintQrCode from '../views/home/PrintQrCode';
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import _ from "lodash";

export default {
  name: "dashboard-navbar",
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    ChangeMainHub,
    PrintQrCode
  },
  data() {
    return {
      currentUser: null,
    };
  },
  computed: {
    hasAssignedHub() {
      return !_.isEmpty(this.$store.getters["worklog/getCurrMainHub"]);
    },
  },
  mounted() {
    this.currentUser = this.$store.getters["auth/getCurrentUser"];
    if (_.isEmpty(this.currentUser)) {
      this.onLogout();
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch("auth/logout");
    },
    onChangeHub() {
      EventBus.$emit("onChangeHub");
    },
    onDownloadQRCode() {
      EventBus.$emit("showDownloadQRCode", this.currentUser);
    },
  },
};
</script>

<style scoped>
.account-name {
  font-size: 14px;
}
</style>
