import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import ArgonDashboard from './plugins/argon-dashboard';
import { auth } from './config/firebase';
import store from './store';
import { BootstrapVue } from 'bootstrap-vue'
import DateUtil from './utils/date';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, digits } from 'vee-validate/dist/rules';
import VueIziToast from 'vue-izitoast';
import { getUser } from './utils/localStorage';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'izitoast/dist/css/iziToast.min.css';

extend('required', { ...required, message: '{_field_} is required' });
extend('email', { ...email, message: '{_field_} is invalid' });
extend('digits', { ...digits, message: '{_field_} must be 6 digits' });

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
Vue.use(BootstrapVue);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueIziToast, { position: 'topRight' });

export const bus = new Vue();

Vue.filter('timeFormat', str => {
  if (!str) return '--';
  return DateUtil.getFormattedTime(str);
});
Vue.filter('dateFormat', str => {
  if (!str) return '--';
  return DateUtil.getFormattedDate(str);
});
Vue.filter('dateAndTimeFormat', str => {
  if (!str) return '--';
  return DateUtil.getFormattedDateWithTime(str);
});

let app = '';
auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('auth/SET_USER_DETAILS', getUser());
  }

  if (!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App),
    }).$mount('#app');
  }
});
