<template>
    <b-modal
        id="download-qrcode"
        title="QR Code Preview"
        ref="modal"
        ok-title="Download"
        ok-variant="success"
        @ok.prevent="onProceed"
        @cancel="onClose"
        :no-close-on-backdrop="true"
    >
        <div class="text-center">
            <canvas id="canvas" :height="frameHeight" :width="frameWidth" />
        </div>
    </b-modal>
</template>

<script>
// Util
import { loadText, loadImages } from '@/utils/canvasUtil'

// Others
import QRCode from 'qrcode';
import EventBus from '@/shared/event-bus';
import _ from 'lodash';
import QrCodeBG from '../../../public/img/qrcode/qrcode_bg.png';


export default {
    name: 'download-qrcode',
    data() {
        return {
            frameHeight: 400,
            frameWidth: 224,
            qrCodeheight: 200,
            qrCodeWidth: 200,

            foregroundColor: '#000000',
            backgroundColor: '#ffffff',
            labelColor: '#9BAEBC',

            size: 24,
            font: 'arial',
            baseline: 'middle',
            angle: 0,
            isTransparent: false,
            user: {},
            dataUrl: ''
        }
    },
    computed: {
        userDisplayName() {
            if (_.isEmpty(this.user)) {
                return '';
            }

            return `${this.user.firstName} ${this.user.lastName}`.trim();
        }
    },
    mounted() {
        EventBus.$on('showDownloadQRCode', async (user) => {
            this.user = user;
            this.$bvModal.show('download-qrcode');
            
            setTimeout(() => {
                this.draw();
            }, 0);
        });
    },
    methods: {
        async draw() {
            const canvas = document.getElementById('canvas');
            const context = canvas.getContext('2d');

            const qrcode = await QRCode.toDataURL(this.user.id);

            const images = {
                img1: QrCodeBG,
                img2: qrcode
            };

            let texts = [
                { 
                    label: {
                        title: 'Name', x: 35, y: 260, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.userDisplayName, x: 115, y: 260, w: 80, h: 10, c: this.foregroundColor
                    }
                }, {
                    label: {
                        title: 'Type', x: 35, y: 280, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.user.type, x: 115, y: 280, w: 80, h: 10, c: this.foregroundColor
                    }
                }, {
                    label: {
                        title: 'Company', x: 35, y: 300, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.user.company, x: 115, y: 300, w: 80, h: 10, c: this.foregroundColor
                    }
                }, {
                    label: {
                        title: 'Contact No.', x: 35, y: 320, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.user.contactNo, x: 115, y: 320, w: 80, h: 10, c: this.foregroundColor
                    }
                }
            ];

            if (this.user.company === 'Unassigned') {
                // Replace deleted object
                const deletedObj = texts.splice(2, 1);
                texts[2].label.y = deletedObj[0].label.y;
                texts[2].value.y = deletedObj[0].value.y;
            }

            loadImages(images, image => {
                context.drawImage(image.img1, 0, 0, 224, 400);
                context.drawImage(image.img2, 12, 12, this.qrCodeWidth, this.qrCodeheight);

                let linesAdded = 0;
                let valueY = 0;
                let labelY = 0;

                texts.forEach((text, index) => {
                    if (index === 0) {
                        valueY = text.value.y;
                        labelY = text.label.y;
                    } else {
                        valueY += 15;
                        labelY += 15;
                    }

                    if (linesAdded > 0) {
                        valueY += linesAdded * 11;
                        labelY += linesAdded * 11;
                        linesAdded = 0; // Reset lines to be added to 0
                    }

                    linesAdded = loadText(
                        context,
                        text.value.title,
                        text.value.x,
                        valueY,
                        text.value.w, 
                        text.value.h, 
                        text.value.c
                    );

                    loadText(
                        context,
                        text.label.title,
                        text.label.x,
                        labelY,
                        text.label.w, 
                        text.label.h, 
                        text.label.c
                    );
                });

                context.restore();
                this.emitDataURL();
            });
        },
        emitDataURL() {
            const dataURL = document.getElementById('canvas').toDataURL('image/png');
            this.dataUrl = dataURL;
        },
        async onProceed() {
            const url = this.dataUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${_.toLower(this.userDisplayName)}.png`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        },
        onClose() {
            this.resetComponent();
            this.$bvModal.hide('download-qrcode');
        },
        resetComponent() {
            const canvas = document.getElementById('canvas');
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);

            this.dataUrl = '';
            this.user = {};
        }
    },
    beforeDestroy() {
        this.resetComponent();
        EventBus.$off('showDownloadQRCode');
    }
}
</script>
