import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '@/layout/DashboardLayout';
import AuthLayout from '@/layout/AuthLayout';
import Page404 from './views/404';
import { auth } from './config/firebase';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      component: DashboardLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
        },
      ]
    },
    {
      path: '/404',
      name: '404',
      meta: { isPublic: true },
      component: Page404,
      alias: '*'
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      meta: {
        isAuthPage: true
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
        }
      ]
    }
  ]
});

// Route Middleware
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const isAuthPage = to.matched.some(x => x.meta.isAuthPage);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/login');
  } else if (currentUser) {
    if (isAuthPage) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;