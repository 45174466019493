import firebase from 'firebase';
// Required for side-effects
require("firebase/firestore");

import config from '@/config/env-constants';
import devConfig from '@/config/dev.env';
import qaConfig from '@/config/qa.env';
import uatConfig from '@/config/uat.env';
import preprodConfig from '@/config/preprod.env';
import prodConfig from '@/config/prod.env';

firebase.initializeApp(getEnvParam(config.currEnv));

const auth = firebase.auth();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const storage = firebase.storage();
const currentTimeStamp = firebase.firestore.FieldValue.serverTimestamp();

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

function getEnvParam(env) {
  if (env === 'dev') {
    return devConfig;
  } else if (env === 'qa') {
    return qaConfig;
  } else if (env === 'uat') {
    return uatConfig;
  } else if (env === 'preprod') {
    return preprodConfig;
  } else if (env === 'prod') {
    return prodConfig;
  }
}

export {
  firebase,
  db,
  auth,
  storage,
  currentUser,
  currentTimeStamp,
  remoteConfig
}