import { db } from '../config/firebase';

async function getAssignedHubs(userId) {
  let assignedHubs = [];

  const query = db.collection('userWorklogSetups')
    .where('userId', '==', userId)
    .limit(1);

  const querySnapshot = await query.get();

  querySnapshot.forEach(doc => {
    const data = doc.data();
    assignedHubs = data.assignedHubs;
  });

  return assignedHubs;
}

export default {
  getAssignedHubs
}