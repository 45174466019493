import router from '../../router';
import { auth } from '../../config/firebase';
import { setUser, setCurrWorkLog } from '../../utils/localStorage';
import { getUserDetailsByEmail } from '../../database/users';

export default {
  state: {
    user: {}
  },
  getters: {
    getCurrentUser(state) {
      return state.user;
    },
    getDisplayName(state) {
      return `${state.user.firstName} ${state.user.lastName}`;
    },
    getDisplayCompany(state) {
      return state.user.company;
    }
  },
  mutations: {
    SET_USER_DETAILS(state, payload) {
      state.user = payload;
      setUser(payload);
    },
    CLEAR_USER_DETAILS(state) {
      state.user = {};
      setUser({});
      setCurrWorkLog({});
    }
  },
  actions: {
    async login({commit, dispatch}, payload) {
      await auth.signInWithEmailAndPassword(payload.email, payload.password);

      // Set User details
      const user = await getUserDetailsByEmail(payload.email);
      commit('SET_USER_DETAILS', user);

      // Set assigned hubs
      await dispatch('worklog/fetchAssignedHubs', null, { root: true });

      router.push('/');
    },
    async logout({commit}) {
      await auth.signOut();
      commit('CLEAR_USER_DETAILS');
      router.push('/login');
    }
  }
}