import DateUtil from './date';

function getFilterBy(filterBy) {
  let updatedFilterBy = Object.assign({}, filterBy);
  updatedFilterBy.dateFrom = DateUtil.startDateTimeStamp(Date.parse(updatedFilterBy.dateFrom));
  updatedFilterBy.dateTo = DateUtil.endDateTimeStamp(Date.parse(updatedFilterBy.dateTo));
  updatedFilterBy.orderBy = 'desc';
  return updatedFilterBy;
}

export default {
  getFilterBy
}